import dynamic from 'next/dynamic';
import Link from 'next/link';

const Icon = dynamic(() => import('../Icon'));

export const HeaderHowToBuy = () => (
  <Link href={'/info/contacts'}>
    <div className="header__how-to-buy">
      <Icon className="header__how-to-buy-icon" name="information" />
      <span className="header__how-to-buy-text">Как купить</span>
    </div>
  </Link>
);
