import { omit } from '../../../utils/omit';

import { routeMapping } from './routeMapping';

export const getQuery = {
  supplier: (currentQuery) => omit(currentQuery, 'supplier'),
  brand: (currentQuery) => omit(currentQuery, 'brand'),
  default: (currentQuery) => currentQuery,
};

export const getPaginatorHref = (page, currentPosition, currentQuery, router, currentType) => {
  if (!page) return null;
  const query = getQuery[currentPosition.name]
    ? getQuery[currentPosition.name](currentQuery)
    : getQuery.default(currentQuery);
  const queryObj = { ...query, page };
  const updatedQueryObj = page === 1 ? omit(queryObj, 'page') : queryObj;
  const myQuery = new URLSearchParams(updatedQueryObj).toString();
  return routeMapping[router.pathname](currentType.type, myQuery, currentPosition);
};
