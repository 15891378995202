import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { cities } from '../../constants/cities';
import { actions } from '../../redux';
import Modal from '../Modal';

const HeaderPositionSelectorPopup = ({ isShowing, toggle }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const city = useSelector((state) => state.profile.city);

  const userCity = cities.find((item) => item.code === city);

  const href = router.asPath;

  const toggleSelect = (city) => {
    if (city) {
      if (userCity.code !== city.code) {
        dispatch(actions.updateCity(city));
        router.push(href);
      }
      toggle();
    }
  };

  return (
    <Modal toggle={toggle} isShowing={isShowing}>
      <div className="modal__inner modal__inner--citySelector">
        <h2 className="modal__dialog-title">Выбор города</h2>
        <div className="modal__select-wrapper">
          <ul className="modal__select">
            {cities.map((city) => (
              <li
                key={city.id}
                onClick={toggleSelect.bind(null, city)}
                className={classNames('modal__select-option', {
                  'modal__select-option--active': city.code === userCity.code,
                })}
              >
                {city.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default HeaderPositionSelectorPopup;
