import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

const Icon = dynamic(() => import('../Icon'));

export const HeaderPhone = () => {
  const { phone } = useSelector((state) => state.beriBarData);

  return !!phone ? (
    <a href={`tel:${phone}}`} className="header__phone">
      <Icon className="header__phone-icon" name="header__phone" />
      <span className="header__phone-text">{phone}</span>
    </a>
  ) : null;
}
