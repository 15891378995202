import removeMarkdown from 'markdown-to-text';

import config from '../../../configs';
import { PagesTypes } from '../../../models/Pages.models';
import { capitalizeFirstLetter } from '../../../utils';
import { getReasonableOffer } from '../../Catalog/helpers';

const DEFAULT_TITLE = 'БериБар';

const pageTitleMapping = {
  '/': () => `${DEFAULT_TITLE} - Главная`,
  '/types/[id]': ({ currentType }) =>
    `${DEFAULT_TITLE} - ${capitalizeFirstLetter(currentType.name_ru)}`,
  '/positions/[id]': ({ position }) =>
    `${DEFAULT_TITLE} - ${
      capitalizeFirstLetter(position.name_ru) || capitalizeFirstLetter(position.name)
    }`,
  '/checkout': () => `${DEFAULT_TITLE} - Оформление заказа`,
  '/basket': () => `${DEFAULT_TITLE} - Корзина`,
  '/info/about': () => `${DEFAULT_TITLE} - О Сервисе`,
  '/info/for_clients': () => `${DEFAULT_TITLE} - Клиентам`,
  '/info/contacts': () => `${DEFAULT_TITLE} - Контакты`,
  '/suppliers': () => `${DEFAULT_TITLE} - Поставщики`,
  '/positions': () => `${DEFAULT_TITLE} - Позиции`,
  '/profile': () => `${DEFAULT_TITLE} - Профиль пользователя`,
  '/search': () => `${DEFAULT_TITLE} - Поиск`,
};

const getPageTitle = (router, data) =>
  pageTitleMapping[router.pathname] ? pageTitleMapping[router.pathname](data) : DEFAULT_TITLE;

const { SITE_URL } = config;

const metaTagsMapping = {
  [PagesTypes.Catalog]: ({ price_min, count, category }) => ({
    title: `${category} купить по цене от ${price_min}. ${count} наименований`,
    description: `${category} купить по лучшей цене от ${price_min}. ${count} наименований. Оптовые поставщики и розница. Первый агрегатор алкогольной продукции БериБар.`,
    keywords: `${category} купить купить цена недорого дешево опт поставщик`,
    openGraph: {
      title: '',
      description: `${category} купить по лучшим ценам в БериБар. Оптовые поставщики и розница. Первый агрегатор алкогольной продукции.`,
      image: `${SITE_URL}images/logo_og.jpg`,
    },
  }),
  [PagesTypes.Card]: ({ position, code }) => {
    const { price } = getReasonableOffer({ offers: position.offers, code });

    return {
      title: `${position.name} - купить по цене от ${price} руб.`,
      description: `${removeMarkdown(position.description)}. Купить по цене ${price}  рублей`,
      keywords: `купить ${position.name} цена`,
      openGraph: {
        title: `${position.name} - купить по цене от ${price} руб.`,
        description: `${removeMarkdown(position.description)}. Купить по цене ${price}  рублей`,
        image: position.photo,
      },
    };
  },
  // [PagesTypes.Main]: ({ price_min, price_max, count, category }) => ({
  //   title: 'asdfasdfsd',
  //   description: 'asdfsdf',
  //   keywords: 'asdfgasdf',
  // }),
  [PagesTypes.Common]: ({ price_min, price_max, count, pageTitle, category }) => ({
    title: pageTitle,
    description: pageTitle,
    keywords: 'БериБар: Ищи здесь, Покупай по своим контрактам',
    openGraph: {
      title: pageTitle,
      description: pageTitle,
      image: `${SITE_URL}images/logo_og.jpg`,
    },
  }),
};

export const getMetaData = (data, router, code) => {
  const {
    currentFilters: { price_min, price_max },
    products: { count },
    currentPosition,
    position,
  } = data;

  const pageTitle = getPageTitle(router, data);

  const args = {
    price_min,
    price_max,
    count,
    pageTitle,
    category: currentPosition.nameRu,
    position,
    code,
  };

  const metaTagsData = metaTagsMapping[currentPosition.metaType]
    ? metaTagsMapping[currentPosition.metaType](args)
    : metaTagsMapping[PagesTypes.Common](args);

  return metaTagsData;
};
