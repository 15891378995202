import classNames from 'classnames';
import Link from 'next/link';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { useMobile } from '../../../hooks/useMobile';
import { actions } from '../../../redux';
import { debounce } from '../../../utils/debounce';
import Icon from '../../Icon';

export const MenuCatalogDesktop = ({
  activeTagGroup,
  setActiveTagGroup,
  tagPagesForActiveItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuStyles, setMenuStyles] = useState({});
  const buttonRef = useRef(null);
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const {
    data: { tagsCore },
  } = useStore().getState();

  const openCatalog = useCallback(() => {
    setIsOpen(true);
    updateMenuStyles(setMenuStyles);
    window.addEventListener('mouseover', handleWindowMouseOver);
  }, []);

  const closeCatalog = useCallback(() => {
    setIsOpen(false);
    window.removeEventListener('mouseover', handleWindowMouseOver);
  }, []);

  const handleWindowMouseOver = useCallback((event) => {
    if (event.target.closest('.menu-catalog') === null) {
      closeCatalog();
    }
  }, []);

  useEffect(() => {
    const handleClickOrMouseEnter = debounce((event) => {
      if (!isOpen) {
        openCatalog();
        return;
      }
      if (event.type === 'click') {
        closeCatalog();
      }
    }, 100);
    const buttonElement = buttonRef.current;

    buttonElement.addEventListener('click', handleClickOrMouseEnter);

    return () => {
      buttonElement.removeEventListener('click', handleClickOrMouseEnter);
    };
  }, [isOpen]);

  const onCloseMenuHandler = () => {
    dispatch(actions.setShowMenu(false));
    dispatch(actions.setBodyOverflowHidden(false));
  };

  const onLinkClickHandler = () => {
    onCloseMenuHandler();
    closeCatalog();
  };

  return (
    <div className="menu-catalog">
      <button
        className={classNames('menu-catalog__button', { 'menu-catalog__button--active': isOpen })}
        ref={buttonRef}
      >
        <Icon
          name="catalog-burger"
          fill={isOpen ? '#FFFFFF' : '#000000'}
          className="menu-catalog__button-icon"
        />
        <span className="menu-catalog__button-text">Каталог товаров</span>
      </button>
      {(isOpen || isMobile) && (
        <>
          <div className="menu-catalog__catalog" style={menuStyles.catalogStyles}>
            <ul className="menu-catalog__product-types">
              {tagsCore.map((item) => (
                <Link
                  href={`/types/${item.alcohol_type.type}`}
                  key={`product-type_${item.alcohol_type.type}`}
                >
                  <a onClick={onLinkClickHandler}>
                    <li
                      className={classNames('menu-catalog__product-types-item', {
                        'menu-catalog__product-types-item--active':
                          item.alcohol_type.type === activeTagGroup?.type,
                      })}
                      onMouseEnter={() => setActiveTagGroup(item.alcohol_type)}
                    >
                      {item.alcohol_type.type_ru}
                    </li>
                  </a>
                </Link>
              ))}
            </ul>
            {activeTagGroup && (
              <div className="menu-catalog__filters-wrapper">
                {tagPagesForActiveItem.map((filter) => (
                  <div className="menu-catalog__filter" key={`filter_${filter.alcohol_sub_type}`}>
                    <span className="menu-catalog__filter-name">{filter.alcohol_sub_type_ru}</span>
                    <ul className="menu-catalog__filter-items-wrapper">
                      {filter.tagValues.map((item) => (
                        <li className="menu-catalog__filter-item" key={`filter-item_${item.name}`}>
                          <Link href={item.link}>
                            <a onClick={onLinkClickHandler}>{item.name}</a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="menu-catalog__catalog-blur"
            style={menuStyles.blurStyles}
            onMouseEnter={closeCatalog}
          />
        </>
      )}
    </div>
  );
};

const updateMenuStyles = (setMenuStyles) => {
  const { bottom: headerOffsetY, width: headerWidth } = document
    .querySelector('.header')
    .getBoundingClientRect();

  const { left: leftButtonOffset } = document
    .querySelector('.menu-catalog')
    .getBoundingClientRect();

  const pageHeight = document.querySelector('.layout').offsetHeight;

  const styles = {
    blurStyles: {
      left: `-${leftButtonOffset}px`,
      height: `calc(${pageHeight}px - ${headerOffsetY}px`,
      width: `${headerWidth}px`,
    },
    catalogStyles: {
      width: `${headerWidth - leftButtonOffset - 30}px`,
    },
  };

  setMenuStyles(styles);
};
