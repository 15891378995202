export const routeMapping = {
  brand: (_type, query, currentPosition) =>
    query ? `/brand/${currentPosition.value}?${query}` : `/brand/${currentPosition.value}`,
  '/': (_type, _query, _currentPosition) => '',
  '/types/[id]': (type, query, _currentPosition) =>
    query ? `/types/${type}?${query}` : `/types/${type}`,
  '/[...tag]': (_type, _query, _currentPosition) => '',
  '/search': (_type, query, _currentPosition) => `/search?${query}`,
  '/brand/[...id]': (_type, query, currentPosition) =>
    routeMapping.brand(_type, query, currentPosition),
  '/brands': (_type, query, _currentPosition) => (query ? `/brands?${query}` : '/brands'),
  '/brand/[[...id]]': (_type, query, currentPosition) =>
    routeMapping.brand(_type, query, currentPosition),
  '/brand/[id]': (_type, query, currentPosition) =>
    routeMapping.brand(_type, query, currentPosition),
  '/supplier/[id]': (_type, query, currentPosition) =>
    query ? `/supplier/${currentPosition.value}/?${query}` : `/supplier/${currentPosition.value}/`,
};
