import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../redux';

const useModal = (cb) => {
  const [isShowing, setIsShowing] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => {
    if (isShowing && cb) cb();
    setIsShowing(!isShowing);
  };

  useEffect(() => {
    dispatch(actions.changeModalState({ isModalShowing: isShowing }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  return { isShowing, toggle };
};

export default useModal;
