import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../redux';

const Icon = dynamic(() => import('../Icon'));

const HeaderOpenCloseButton = () => {
  const dispatch = useDispatch();

  const isSearchOpen = useSelector((state) => state.window.isSearchOpen);

  const onBtnClick = () => dispatch(actions.setIsSearchOpen(!isSearchOpen));

  return (
    <div onClick={onBtnClick} className="header__openclose-button">
      {isSearchOpen ? (
        <Icon
          className="header__openclose-button-icon"
          name="header__search-cross"
          fill="#ffffff"
        />
      ) : (
        <Icon className="header__openclose-button-icon" name="header__search" fill="#ffffff" />
      )}
    </div>
  );
};

export default HeaderOpenCloseButton;
