import { useEffect, useState } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { MAX_MOBILE_SCREEN_WIDTH } from '../constants';

export const useMobile = () => {
  const [state, setState] = useState(false);
  const screenWidth = useSelector((state) => state.window.size.screenWidth);

  useEffect(() => {
    const isSmallScreen = screenWidth
      ? (isMobile || isDesktop) && screenWidth <= MAX_MOBILE_SCREEN_WIDTH
      : isMobile;
    setState(isSmallScreen);
  }, [screenWidth]);

  return state;
};
