import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import Icon from '../Icon';

const HeaderProfile = ({ toggle }) => {
  const isAuthorized = useSelector((state) => state.profile.isAuthorized);

  const router = useRouter();

  const onClickHandler = () => (isAuthorized ? router.push('/profile') : toggle());

  return (
    <div onClick={onClickHandler} className="header__profile">
      <Icon
        name="header__user"
        className="header__profile-icon"
      />
    </div>
  );
};
export default HeaderProfile;
