import { useMemo } from 'react';
import { useStore } from 'react-redux';

import { MenuCatalogDesktop } from './Desktop';
import { MenuCatalogMobile } from './Mobile';
import { groupBySubtype } from './helpers/groupBySubtype';

export const MenuCatalog = (props) => {
  const {
    data: { tagsCore },
  } = useStore().getState();

  const tagPagesForActiveItem = useMemo(
    () =>
      groupBySubtype(
        tagsCore.find((item) => item.alcohol_type.type === props.activeTagGroup?.type)?.tag_pages
      ),
    [tagsCore, props.activeTagGroup]
  );

  return (
    <>
      <MenuCatalogMobile {...props} tagPagesForActiveItem={tagPagesForActiveItem} />
      <MenuCatalogDesktop {...props} tagPagesForActiveItem={tagPagesForActiveItem} />
    </>
  );
};
