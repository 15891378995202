import config from '../../../configs';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { getPaginatorHref } from '../../Catalog/helpers';

export const getHeadLinks = (count, currentQuery, currentType, currentPosition, router) => {
  if (!currentPosition.extraMarkup) return { prev: null, next: null };

  const { SITE_URL } = config;
  const { page_size, page = 1 } = currentQuery;
  const currentPage = Number(page);
  const pageCount = Math.ceil(count / (page_size ? page_size : DEFAULT_PAGE_SIZE));

  const showPrev = currentPage - 1 >= 1;
  const showNext = currentPage + 1 <= pageCount;

  const prevHref = getPaginatorHref(
    currentPage - 1,
    currentPosition,
    currentQuery,
    router,
    currentType
  );

  const nextHref = getPaginatorHref(
    currentPage + 1,
    currentPosition,
    currentQuery,
    router,
    currentType
  );

  return {
    prev: showPrev ? new URL(prevHref, SITE_URL).href : null,
    next: showNext ? new URL(nextHref, SITE_URL).href : null,
  };
};
