export const getButtonsList = (pageCount, currentPage) => {
  const emptyButtonDataWithDots = { label: 'dots' };

  const page = Number(currentPage);

  const buttonsData = [
    ...new Set([
      1,
      page - 5,
      page - 4,
      page - 3,
      page - 2,
      page - 1,
      page,
      page + 1,
      page + 2,
      page + 3,
      page + 4,
      page + 5,
      pageCount,
    ]),
  ]
    .filter((item) => item > 0 && item <= pageCount)
    .filter((item) => {
      if (item === 1 || item === pageCount) return true;
      if (currentPage <= 3) return item < 7;
      if (currentPage >= pageCount - 3) return item > pageCount - 6;
      return item >= page - 2 && item <= page + 2;
    })
    .reduce((acc, item) => {
      const itemWithData = { page: item, label: item };
      if (item === 1 && page > 4) {
        return [...acc, itemWithData, emptyButtonDataWithDots];
      }
      if (item === pageCount && page < pageCount - 3) {
        return [...acc, emptyButtonDataWithDots, itemWithData];
      }
      return [...acc, itemWithData];
    }, []);

  return buttonsData;
};
