import Modal from '../Modal';

import LoginPopupContent from './LoginPopupContent';

const LoginPopup = ({ isShowing, toggle }) => (
  <Modal toggle={toggle} isShowing={isShowing}>
    <LoginPopupContent />
  </Modal>
);

export default LoginPopup;
