export const groupBySubtype = (tagPages) => {
  if (!tagPages) {
    return [];
  }

  const grouped = tagPages.reduce((prev, curr) => {
    return { ...prev, [curr.alcohol_sub_type]: (prev[curr.alcohol_sub_type] || []).concat(curr) };
  }, {});
  const formattedAsArray = Object.entries(grouped).map(([key, valuesArray]) => ({
    alcohol_sub_type: key,
    alcohol_sub_type_ru: valuesArray[0].alcohol_sub_type_ru,
    tagValues: valuesArray,
  }));

  return formattedAsArray;
};
