import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { useUrl } from '../../hooks/useUrl';

import { getHeadLinks } from './helpers/getHeadLinks';
import { getMetaData } from './helpers/getMetaData';

const PageHead = ({ script }) => {
  const router = useRouter();
  const data = useSelector((state) => state.data);
  const city = useSelector((state) => state.profile.city);
  const {
    currentPosition,
    currentQuery,
    currentType,
    products: { count },
  } = data;

  const url = useUrl();

  const { title, description, keywords, openGraph } = getMetaData(data, router, city);

  const { prev, next } = getHeadLinks(count, currentQuery, currentType, currentPosition, router);

  return (
    <Head>
      {!!script && parse(script)}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="yandex-verification" content="7e295a3b7c0f7534" />
      <link rel="icon" href="/favicon.ico" />
      {prev && <link rel="prev" href={prev} />}
      {next && <link rel="next" href={next} />}
      <meta property="og:title" content={openGraph.title} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="БериБар: Ищи здесь, Покупай по своим контрактам" />
      <meta property="og:description" content={openGraph.description} />
      <meta property="og:image" content={openGraph.image} />
    </Head>
  );
};

export default PageHead;
