import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cities } from '../../constants/cities';
import { actions } from '../../redux';

const Icon = dynamic(() => import('../Icon'));

const HeaderCityMobile = ({ onCloseMenuHandler }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const city = useSelector((state) => state.profile.city);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const userCity = cities.find((item) => item.code === city);

  const href = router.asPath;

  const selectCity = (city) => {
    setIsPopupOpen(false);
    if (userCity.code !== city.code) {
      onCloseMenuHandler();
      dispatch(actions.updateCity(city));
      router.push(href);
    }
  }

  const handleClickOutsidePopup = (event) => {
    event.preventDefault();
    setIsPopupOpen(false);
  }

  return (
    <>
      <div
        className={classNames('mobile-city-selector__blur', {
          'show--blur': isPopupOpen,
        })}
        onClick={handleClickOutsidePopup}
      />
      <div className="mobile-city-selector">
        <span className="mobile-city-selector__wrapper" onClick={() => setIsPopupOpen(true)}>
          <Icon className="mobile-city-selector__icon" name="header__location--mobile" />
          <p className="mobile-city-selector__current-city">{userCity.name}</p>
        </span>
        {isPopupOpen && (
          <div className="mobile-city-selector__popup">
            <Icon
              onClick={() => setIsPopupOpen(false)}
              className="mobile-city-selector__popup-close-btn"
              name="header__close-menu-btn--mobile"
              fill="#026db0"
            />
            <span className="mobile-city-selector__popup-info">Выбор города</span>
            <ul className="mobile-city-selector__popup-list">
              {cities.map((city) => (
                <li
                  key={`city_${city.code}`}
                  className={classNames('mobile-city-selector__popup-list-item', {
                    'mobile-city-selector__popup-list-item--active': city.code === userCity.code,
                  })}
                  onClick={selectCity.bind(null, city)}
                >
                  <span>{city.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
};

export default HeaderCityMobile;
