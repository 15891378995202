import { createSelector } from '@reduxjs/toolkit';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useWindowSize } from '../../hooks/useWindowSize';
import { actions } from '../../redux';
import Footer from '../Footer';
import Head from '../Head';
import Header from '../Header';
import Popup from '../Popup';

const Spinner = dynamic(() => import('../Spinner'));
const Notification = dynamic(() => import('../Notification'));

const selector = createSelector(
  (state) => state.window.isModalShowing,
  (state) => state.window.isBodyOverflowHidden,
  (state) => state.user,
  (state) => state.config,
  (state) => state.profile,
  (
    isModalShowing,
    isBodyOverflowHidden,
    { isUserEmpty, isScroll, scrollPosition },
    config,
    { isAuthorized }
  ) => ({
    isModalShowing,
    isBodyOverflowHidden,
    isUserEmpty,
    isScroll,
    scrollPosition,
    config,
    isAuthorized,
  })
);

const Layout = ({ children, home = false, showFooter = true }) => {
  const {
    isModalShowing,
    isBodyOverflowHidden,
    isUserEmpty,
    isScroll,
    scrollPosition,
    config: { TAG_MANAGER_FOR_HEAD, TAG_MANAGER_FOR_BODY },
    isAuthorized,
  } = useSelector(selector);

  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!isAuthorized) Cookies.remove('token');
  }, [isAuthorized]);

  useEffect(() => {
    const catalog = document.querySelector('#catalog');
    if (isScroll && catalog) {
      window.scroll(0, scrollPosition);
      dispatch(actions.setIsScroll(false));
      dispatch(actions.setScrollPosition(0));
    }

    if (isUserEmpty) dispatch(actions.updateUser());
    dispatch(actions.setIsClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isScrollBlocked = isModalShowing || isBodyOverflowHidden;
    if (isScrollBlocked) {
      document.body.classList.add('overflow');
    } else {
      document.body.classList.remove('overflow');
    }
  }, [isModalShowing, isBodyOverflowHidden]);

  useEffect(() => {
    dispatch(actions.setWindowSize(windowSize));
  }, [windowSize, dispatch]);

  return (
    <>
      <div className="layout">
        <Head script={TAG_MANAGER_FOR_HEAD.value} />
        <Header />
        <div className="layout__inner">
          <Spinner />
          {children}
        </div>
        {showFooter && <Footer home={home} />}
        <Popup />
        <Notification />
      </div>
      {!!TAG_MANAGER_FOR_BODY && parse(TAG_MANAGER_FOR_BODY.value)}
    </>
  );
};

export default Layout;
