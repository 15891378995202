import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { pageRoutes } from '../../api';

const HeaderUserInfo = ({ toggle, onCloseMenuHandler }) => {
  const { first_name, last_name, isAuthorized } = useSelector((state) => state.profile);
  const router = useRouter();

  const onClickHandler = () => {
    if (isAuthorized) {
      router.push(pageRoutes.PROFILE);
    } else {
      toggle();
    }
    onCloseMenuHandler();
  };

  return (
    <span onClick={onClickHandler} className="header__user-info">
      <img className="header__menu-profile-icon--mobile" src="/images/header__user.svg" alt="" />
      <p className="header__user-name">{isAuthorized ? `${first_name} ${last_name}` : 'Вход'}</p>
    </span>
  );
};

export default HeaderUserInfo;
