import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const ModalContext = createContext();

const Modal = ({ children, toggle, isShowing, full = false }) => {
  const onClickHandler = (e) => {
    if (e.target.className === 'modal__body') {
      toggle();
    }
  };

  const [modalOffset, setModalOffset] = useState();

  const modalStyles = { top: `${modalOffset}px` };

  const {
    size: { screenWidth, screenHeight },
  } = useSelector((state) => state.window);

  useEffect(() => {
    const headerDesktopHeight = document.querySelector('.header').offsetHeight;
    setModalOffset(screenWidth && screenWidth <= 768 ? headerDesktopHeight : 0);
  }, [screenWidth]);

  return (
    <ModalContext.Provider value={{ toggle }}>
      {isShowing && (
        <div style={modalStyles} onClick={onClickHandler} className="modal">
          <div className="modal__body">
            <div className="modal__container">
              <span onClick={toggle} className="modal__popup-close-btn" />
              {children}
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export default Modal;
