import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';

import { useMobile } from '../../hooks/useMobile';
import { actions } from '../../redux';

import HeaderCityMobile from './HeaderCityMobile';
import HeaderUserInfo from './HeaderUserInfo';
import { MenuCatalog } from './MenuCatalog/MenuCatalog';
import { getItemClassnames } from './helpers';

const Icon = dynamic(() => import('../Icon'));

const HeaderMenu = ({ toggle }) => {
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const showMenu = useSelector((state) => state.window.showMenu);
  const { activeMenuItem } = useSelector((state) => state.data.currentPosition);
  const isMobile = useMobile();
  const [activeItem, setActiveItem] = useState(activeMenuItem);
  const [activeTagGroup, setActiveTagGroup] = useState(null);

  const headerMenuClassnames = classNames('header__nav-wrapper', {
    'header__nav-wrapper--show': showMenu,
  });

  useEffect(() => {
    setActiveItem(activeMenuItem);
  }, [activeMenuItem]);

  const {
    data: { productTypes },
  } = useStore().getState();

  const onCloseMenuHandler = () => {
    dispatch(actions.setShowMenu(false));
    dispatch(actions.setBodyOverflowHidden(false));
  };
  const onBackgroundClickHandler = (event) => {
    if (event.clientX > menuRef.current.clientWidth) {
      dispatch(actions.setShowMenu(false));
      dispatch(actions.setBodyOverflowHidden(false));
    }
  };

  const onItemClickHandler = (name) => {
    setActiveItem(name);
    onCloseMenuHandler();
  };

  return (
    <nav onClick={onBackgroundClickHandler} className={headerMenuClassnames}>
      <div className="header__menu-controls--mobile">
        <span className="header__btn--close">
          <Icon
            onClick={onCloseMenuHandler}
            className="header__close-menu-btn header__close-menu-btn--mobile"
            name="header__close-menu-btn"
            fill="#ffffff"
          />
        </span>
      </div>
      <div ref={menuRef} className="header__menu">
        <div className="header__menu-wrapper">
          {!(isMobile && activeTagGroup) && (
            <>
              <HeaderCityMobile onCloseMenuHandler={onCloseMenuHandler} />
              <HeaderUserInfo toggle={toggle} onCloseMenuHandler={onCloseMenuHandler} />
            </>
          )}
          <ul className={classNames('header__nav-list', { 'tag-group-selected': activeTagGroup })}>
            <MenuCatalog activeTagGroup={activeTagGroup} setActiveTagGroup={setActiveTagGroup} />

            {!isMobile && (
              <div className="header__product-types-container">
                {productTypes
                  .filter((item) => item.offers_count && item.is_visible_in_header)
                  .map((item) => {
                    const itemClassnames = getItemClassnames(activeItem, item.type);
                    return (
                      <li className={itemClassnames} key={item.type}>
                        <Link href={`/types/${item.type}`}>
                          <a onClick={() => onItemClickHandler(item.type)}>{item.name_ru}</a>
                        </Link>
                      </li>
                    );
                  })}
              </div>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HeaderMenu;
