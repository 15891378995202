import dynamic from 'next/dynamic';
import { useContext, useState } from 'react';

import { ModalContext } from '../Modal/Modal';

import { LoginContext } from './LoginContext';

const LoginInputPhone = dynamic(() => import('./LoginInputPhone'));
const LoginInputSMS = dynamic(() => import('./LoginInputSMS'));

const screenMapping = {
  inputPhone: <LoginInputPhone />,
  inputSMS: <LoginInputSMS />,
};

const LoginPopupContent = () => {
  const { toggle } = useContext(ModalContext);
  const [currentScreen, setCurrentScreen] = useState('inputPhone');
  const [phoneValue, setPhoneValue] = useState('');

  return (
    <div className="modal__inner modal__inner--login">
      <h2 className="login__title">Вход</h2>
      <LoginContext.Provider value={{ toggle, setCurrentScreen, phoneValue, setPhoneValue }}>
        {screenMapping[currentScreen]}
      </LoginContext.Provider>
    </div>
  );
};

export default LoginPopupContent;
