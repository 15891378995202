import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useSelector } from 'react-redux';

const Icon = dynamic(() => import('../Icon'));

const HeaderBasket = () => {
  const {
    basket: { size },
  } = useSelector((state) => state.user);

  return (
    <Link href={'/basket'}>
      <div className="header__basket">
        <Icon
          name="basket"
          className="header__basket-icon"
        />
        <div className="header__basket-counts">
          <span className="header__basket-counts-number">{size}</span>
        </div>
      </div>
    </Link>
  );
};
export default HeaderBasket;
