import { HeaderCityDesktop } from './HeaderCityDesktop';
import { HeaderHowToBuy } from './HeaderHowToBuy';
import { HeaderPhone } from './HeaderPhone';

const HeaderInfo = () => (
  <div className="header__info">
    <div className="wrapper">
      <div className="header__info-inner">
        <div className="header__warning">
          <img
            src="/images/icon__warning.svg"
            alt="Сожержание сайта предназначена для лиц старше 18 дет"
            width="22"
            height="22"
          />
          <span className="header__tooltip">
              Содержание сайта предназначено для лиц старше 18 лет
          </span>
        </div>
        <div className="header__contacts">
          <HeaderCityDesktop />
          <div className="header__contacts-divider-wrapper">
            <div className="header__contacts-divider" />
          </div>
          <HeaderHowToBuy />
          <div className="header__contacts-divider-wrapper">
            <div className="header__contacts-divider" />
          </div>
          <HeaderPhone />
        </div>
      </div>
    </div>
  </div>
);

export default HeaderInfo;
