import { useEffect, useState } from 'react';

import { debounce } from '../utils/debounce';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    screenWidth: undefined,
    screenHeight: undefined,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
