/**
 * Return reasonable price for position from offers
 *
 * @param {Object} args description
 * @param {array} args.offers aray with offers
 * @param {string} args.code current city code
 * @param {array} args.filteredVolumes filteredVolumes
 * @param {boolean} args.isSortingByPriceOn can be true if sorting by price is on. Used only on catalog page
 * @return {{offer: object} } - offer with price, volume
 */

export const getReasonableOffer = (args) => {
  const { offers, code, filteredVolumes = [], isSortingByPriceOn = false } = args;

  const offersFilteredByCity = offers.filter((item) =>
    item.cities.find((item) => item.code === code)
  );

  const [priorityOffer] = offers.filter((offer) => offer.priority_offer);

  const isPriorityOfferAvailable = !!priorityOffer?.cities.find((item) => item.code === code);

  const volume = priorityOffer?.volume || null;

  if (isPriorityOfferAvailable && filteredVolumes.length === 0 && !isSortingByPriceOn)
    return { ...priorityOffer, priorityOffer: true };

  const filteredOffers = offersFilteredByCity.filter((offer) =>
    filteredVolumes.length > 0 ? filteredVolumes.includes(offer.volume) : true
  );

  if (!filteredOffers.length) return { volume };

  const offer = filteredOffers.reduce((acc, item) =>
    Number(item.price) <= Number(acc.price) ? item : acc
  );
  return { ...offer, priorityOffer: false, volume };
};
