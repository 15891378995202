import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from '../../redux';

const Popup = () => {
  const { isUserAboveTheAgeOf18 } = useSelector((state) => state.user);
  const { isClient } = useSelector((state) => state.window);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.changeUserStatus({ isUserAboveTheAgeOf18 }));
    dispatch(actions.setBodyOverflowHidden(!isUserAboveTheAgeOf18));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAboveTheAgeOf18]);

  const onClickHandler = () => {
    Cookies.set('isUserAboveTheAgeOf18', 'true');
    dispatch(actions.changeUserStatus({ isUserAboveTheAgeOf18: true }));
    dispatch(actions.setBodyOverflowHidden(false));
  };

  return !isClient ? null : isUserAboveTheAgeOf18 ? null : (
    <div className="popup">
      <div className="popup__inner">
        <img
          className="popup__img"
          src={'/images/icon__warning.svg'}
          width="66"
          height="66"
          alt="Картинка с предупреждением"
        />
        <p className="popup__text">
          Сайт содержит информацию для лиц совершеннолетнего возраста. Сведения, размещенные на
          сайте, не являются рекламой, носят исключительно информационный характер, и предназначены
          только для личного использования.
        </p>
        <button className="popup__btn" onClick={onClickHandler}>
          Мне исполнилось 18 лет
        </button>
      </div>
    </div>
  );
};

export default Popup;
