import dynamic from 'next/dynamic';
import Link from 'next/link';
import { memo } from 'react';
import { useSelector } from 'react-redux';

const Icon = dynamic(() => import('../Icon'));

const headerTitleContent = 'Крупнейший агрегатор поставщиков алкоголя в заведения';

const HeaderMainTitle = () => <h1 className="header__logo-text">{headerTitleContent}</h1>;
const HeaderCommonTitle = () => <span className="header__logo-text">{headerTitleContent}</span>;

const HeaderLogo = () => {
  const currentPosition = useSelector((state) => state.data.currentPosition);

  const HeaderTitle = currentPosition.name === 'main' ? HeaderMainTitle : HeaderCommonTitle;

  return (
    <Link href={'/'}>
      <a>
        <div className="header__logo header__logo--desktop">
          <Icon className="header__logo-img header__logo-img--01" name="logo__beri" />
          <Icon className="header__logo-img header__logo-img--02" name="logo__bar--desktop" />
          <HeaderTitle />
        </div>
        <div className="header__logo header__logo--tablet">
          <img className="header__logo-img" src="/header__logo--table.svg" alt="" />
        </div>
      </a>
    </Link>
  );
};

export default memo(HeaderLogo);
