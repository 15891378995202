import Link from 'next/link';
import { memo } from 'react';
import { useSelector, useStore } from 'react-redux';

import { pageRoutes } from '../../api';

const Footer = () => {
  const { opening_hours, phone } = useSelector((state) => state.beriBarData);

  const links = [
    { name: 'О сервисе', href: pageRoutes.ABOUT },
    { name: 'Клиентам', href: pageRoutes.FOR_CLIENTS },
    { name: 'Контакты', href: pageRoutes.CONTACTS },
    { name: 'Политика конфиденциальности', href: pageRoutes.PRIVACY_POLICY },
    { name: 'Пользовательское соглашение', href: pageRoutes.AGREEMENT },
  ];

  return (
    <footer className="footer">
      <div itemScope="" itemType="https://schema.org/Organization" style={{ display: 'none' }}>
        <meta itemProp="name" content="BeriBar" />
        <link itemProp="url" href="http://beribar.ru/" />
        <link itemProp="logo" href="http://beribar.ru/images/logo_og.jpg" />
        <meta
          itemProp="description"
          content="Крупнейший агрегатор поставщиков алкоголя в заведения"
        />
        <meta itemProp="telephone" content={phone} />
      </div>
      <div className="wrapper wrapper--1190">
        <div className="footer__inner">
          <div className="footer__info-column footer__info-column--logo">
            <Link href={'/'}>
              <a className="footer__logo-img">
                <img
                  className=" footer__logo-img--01"
                  src="/images/logo.svg"
                  alt="Берибар: Ищи здесь Покупай по своим контрактам"
                  width="105"
                  height="30"
                />
              </a>
            </Link>
          </div>
          <div className="footer__info-column footer__info-column--margin">
            <div>
              {!!phone && (
                <a className="footer__info-text footer__info-text--big" href={`tel:${phone}`}>
                  {phone}
                </a>
              )}
              <p className="footer__info-text">{opening_hours}</p>
            </div>
          </div>
          <ul className="footer__links">
            {links.map((item, i) => {
              return (
                <li key={i} className="footer__link-item">
                  <Link href={item.href}>
                    <a className="footer__link">{item.name}</a>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="footer__copyrights-wrapper">
            <div className="footer__info-column">
              <p className="footer__copyright-text footer__copyright-text--upp">
                © 2021 — beribar.ru
              </p>
            </div>
            {/* <div className="footer__info-column">
              <p className="footer__copyright-text">Политика конфиденциальности</p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
