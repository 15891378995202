import classNames from 'classnames';
import Link from 'next/link';
import { useDispatch, useStore } from 'react-redux';

import { actions } from '../../../redux';
import Icon from '../../Icon';

export const MenuCatalogMobile = ({ activeTagGroup, setActiveTagGroup, tagPagesForActiveItem }) => {
  const dispatch = useDispatch();

  const {
    data: { tagsCore },
  } = useStore().getState();

  const onCloseMenuHandler = () => {
    dispatch(actions.setShowMenu(false));
    dispatch(actions.setBodyOverflowHidden(false));
  };

  const onLinkClickHandler = () => {
    onCloseMenuHandler();
  };

  return (
    <div className={classNames('menu-catalog--mobile', { 'tag-group-selected': activeTagGroup })}>
      {activeTagGroup ? (
        <div className="menu-catalog--mobile__filters-wrapper">
          <button
            className="menu-catalog--mobile__back-button"
            onClick={() => setActiveTagGroup(null)}
          >
            <Icon
              name="arrow-bold"
              fill="#000000"
              className="menu-catalog--mobile__back-button--icon"
            />
            Назад
          </button>
          <div className="menu-catalog--mobile__alcohol-type-name">{activeTagGroup?.type_ru}</div>
          <div className="menu-catalog--mobile__subtypes-container">
            {tagPagesForActiveItem.map((filter) => (
              <div
                className="menu-catalog--mobile__filter"
                key={`filter_${filter.alcohol_sub_type}`}
              >
                <div className="menu-catalog--mobile__filter-name">
                  {filter.alcohol_sub_type_ru}
                </div>
                <ul className="menu-catalog--mobile__filter-items-wrapper">
                  {filter.tagValues.map((item) => (
                    <li
                      className="menu-catalog--mobile__filter-item"
                      key={`filter-item_${item.name}`}
                    >
                      <Link href={item.link}>
                        <a onClick={onLinkClickHandler}>{item.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <ul className="menu-catalog--mobile__product-types">
          <Link href="/wineries">
            <a className="menu-catalog--mobile__product-types-item" onClick={onLinkClickHandler}>
              Русские винодельни
            </a>
          </Link>
          {tagsCore.map((item) => (
            <li
              className={classNames('menu-catalog--mobile__product-types-item', {
                'menu-catalog--mobile__product-types-item--active':
                  item.alcohol_type.type === activeTagGroup?.type,
              })}
              onClick={() => setActiveTagGroup(item.alcohol_type)}
              key={`product-type_${item.alcohol_type.type}`}
            >
              {item.alcohol_type.type_ru}
              <Icon name="arrow-bold" />
            </li>
          ))}
        </ul>
      )}
      <div className="menu-catalog--mobile__catalog-blur" />
    </div>
  );
};
