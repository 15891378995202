import { createSelector } from '@reduxjs/toolkit';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useModal from '../../hooks/useModal';
import { actions } from '../../redux';
import LoginPopup from '../LoginPopup';

import { HeaderCityDesktop } from './HeaderCityDesktop';
import { HeaderContacts } from './HeaderContacts';
import HeaderInfo from './HeaderInfo';
import HeaderLogo from './HeaderLogo';
import HeaderMenu from './HeaderMenu';
import HeaderOpenCloseButton from './HeaderOpenCloseButton';
import { HeaderUserMenu } from './HeaderUserMenu';

const HeaderSearch = dynamic(() => import('./HeaderSearch'));
const Icon = dynamic(() => import('../Icon'));

const selector = createSelector(
  (state) => state.window,
  ({ isSearchOpen, showMenu }) => ({
    isSearchOpen,
    showMenu,
  })
);

const Header = () => {
  const dispatch = useDispatch();

  const { isSearchOpen, showMenu } = useSelector(selector);
  const { isShowing, toggle } = useModal();

  const onOpenMenuClickHandler = () => {
    dispatch(actions.setShowMenu(true));
    dispatch(actions.setBodyOverflowHidden(true));
  };

  useEffect(() => {
    dispatch(actions.setBodyOverflowHidden(isSearchOpen));
  }, [dispatch, isSearchOpen]);

  const headerBlur = cn('header__blur header__blur--search', {
    'show--menu': showMenu,
    'show--search': isSearchOpen,
  });

  /*window.addEventListener('scroll', this.handleScroll);
  const handleScroll = () => {
    let scroll = document.documentElement.scrollTop;
    if (scroll > 128) {
      this.setState({
        fixed: true,
      });
    } else {
      if(!this.props.isHeaderFixed) {
        this.setState({
          fixed: false,
        });
      }
    }
  }*/

  return (
    <header className="header">
      <LoginPopup isShowing={isShowing} toggle={toggle} />
      <HeaderInfo />
      <div className="header__main">
        <div className="wrapper">
          <div className="header__main-wp">
            <Icon
              onClick={onOpenMenuClickHandler}
              className="header__menu-btn"
              name="header__open-menu-btn"
            />
            <HeaderLogo />
            <HeaderCityDesktop />
            <HeaderOpenCloseButton />
            <HeaderSearch />
            <HeaderContacts />
            <HeaderUserMenu toggle={toggle} />
          </div>
        </div>
      </div>
      <div className="header__menu-wrapper">
        <div className="wrapper">
          <HeaderMenu toggle={toggle} />
        </div>
      </div>
      <div className={headerBlur} />
    </header>
  );
};

export default memo(Header);
