import { HeaderHowToBuy } from './HeaderHowToBuy';
import { HeaderPhone } from './HeaderPhone';

export const HeaderContacts = () => (
  <div className="header__contacts">
    <HeaderHowToBuy />
    <div className="header__contacts-divider-wrapper">
      <div className="header__contacts-divider" />
    </div>
    <HeaderPhone />
  </div>
)
