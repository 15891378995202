import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

import useModal from '../../hooks/useModal';

import HeaderPositionSelectorPopup from './HeaderPositionSelectorPopup';

const Icon = dynamic(() => import('../Icon'));

export const HeaderCityDesktop = () => {
  const { position } = useSelector((state) => state.user);

  const { isShowing, toggle } = useModal();

  const onClickHandler = () => toggle();

  return (
    <>
      <HeaderPositionSelectorPopup isShowing={isShowing} toggle={toggle} />
      <button
        className="header__city-selector"
        onClick={onClickHandler}
      >
        <Icon className="header__city-selector-icon" name="header__location" />
        <span className="header__city-selector-text">{position.name}</span>
      </button>
    </>
  );
}
