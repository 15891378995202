export const getCitiesInfo = (offers) => {
  const cities = offers.reduce((acc, offer) => {
    const citiesFromOffer = offer.cities.map((city) => city.code);
    return [...acc, ...citiesFromOffer];
  }, []);

  const filteredCities = [...new Set(cities)];
  return filteredCities;
};

export const getPositionInfo = (city, offers) => {
  const cities = getCitiesInfo(offers);
  const isPositionAvailable = !!cities.find((item) => item === city);
  return isPositionAvailable;
};
